<template>
    <div>
        <v-img
            class="mx-auto"
            max-width="240"
            src="../assets/default.jpg" />
        <h1 class="font-weight-medium">{{ $t('viewReport.title')}} {{this.$store.state.organization.name}}</h1>
        <p>{{ $t('viewReport.infoText')}} 
        </p>
        <v-row class="mt-8">
            <v-col class="contact-info-box">
                <div>
                    <h2>{{ $t('viewReport.link')}} </h2>
                    <a v-bind:href="report.reportAccessURL" target="_blank">{{report.reportAccessURL}}</a>
                    <h2>{{ $t('viewReport.pinCode')}} </h2>
                    <p>{{report.reportAccessPin}}</p>
                </div>
            </v-col>
        </v-row>
        <v-row class="mt-8">
                <v-col class="contact-info-box">
                    <h2>{{ $t('confirmation.contactInformation')}} </h2>
                    <div class="mb-4" v-if="!report.reporter">
                        {{ $t('confirmation.anonymous')}}
                    </div>
                    <div class="mb-4" v-else>
                        <h3>{{ $t('contactInfo.name')}}</h3>
                        <p>{{report.reporter.name}}</p>
                        <h3>{{ $t('contactInfo.email')}}</h3>
                        <p>{{report.reporter.email}}</p>
                        <h3>{{ $t('contactInfo.phone')}}</h3>
                        <p>{{report.reporter.phone}}</p>
                    </div>
                    <div v-if="!report.notificationEmail">
                        <h2>{{ $t('confirmation.noEmailNotifications')}}</h2>
                    </div>
                    <div v-else>
                        <h2>{{ $t('confirmation.emailNotifications')}}</h2>
                        <p>{{report.notificationEmail}}</p>
                        <div class="d-flex" style="margin-top: 5px; align-items: center;">
                        <v-img src="/img/icons/info-circle.svg" height="1rem" width="1rem" style="flex-grow: 0; margin-right: 5px" />
                        <p><strong>{{ $t('confirmation.emailNotShared')}} {{this.$store.state.organization.name}} -{{ $t('confirmation.toOrganization')}}</strong></p>
                        </div>
                    </div>
                </v-col>
            </v-row>
        <v-row class="mt-8">
                <v-col>
                    <h2>{{ $t('reportForm.subject')}}</h2>
                    <p>{{report.incidentTitle}}</p>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <h2>{{ $t('reportForm.time')}}</h2>
                    <p>{{report.timeOfIncident}}</p>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <h2>{{ $t('reportForm.place')}}</h2>
                    <p>{{report.placeOfIncident}}</p>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <h2>{{ $t('reportForm.details')}}</h2>
                    <p>{{report.content}}</p>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <h2>{{ $t('contactInfo.addedFiles')}}</h2>
                    <div v-if="report.attachments">
                        <v-list-item-group color="primary">
                                <v-list-item v-for="(item, index) in report.attachments" :key="index">
                                    <a>{{ item.name }}</a>
                                </v-list-item>
                        </v-list-item-group>
                    </div>
                </v-col>
            </v-row>
    </div>
</template>

<style scoped>
.contact-info-box {
    border: 1px solid #9FA0AB;
    background-color: #F6F6F7;
    padding: 25px;
}

p {
    margin: 0;
}

h2 {
    font-size: 1.2rem;
    font-weight: 500;
}

.contact-info-box h3 {
    font-size: 1rem;
    font-weight: 500;
    margin-top: 10px;
}

</style>

<script>
export default {
    data() {
        return {
            report: {}
        }
    },
    mounted() {
        this.report = this.$store.state.reportResponse.data.item
        console.log(this.$store.state.reportResponse.data.item)
        this.$store.commit("resetState")
        console.log(this.$store.state)
    }
}
</script>
